import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToTop from "../includes/ToTop";
import $ from "jquery";

import { useState } from "react";
import { useSelector } from "react-redux";
import AdminListService from "../../../services/admin-list.service";
import Pagination from "react-js-pagination";
import { useRef } from "react";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

export default function SearchMplJob() {
  const state = useSelector((state) => state.stateVals);

  const { pavlina_id } = state;
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showCertiRecord, setShowCertiRecord] = useState(false);
  const [CertifecateRecordData, setCertifecateRecordData] = useState([]);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [emailStatus, setEmailStatus] = useState([]);

  // const [jobIds, setJobIds] = useState("");

  const selectKey = useRef(null);
  const statusKey = useRef(null);

  let job = "";
  let cert = "";
  let customer = "";
  let voucher = "";
  let denomination = "";
  let paNumber = "";
  let random_certificate = "";
  let clientCost = "";
  let begin = "";
  let end = "";
  let invoiceNo = "";
  let redeemDate = "";
  let firstName = "";
  let lastName = "";
  let address = "";
  let city = "";
  let stateName = "";
  let zip = "";
  let phone = "";
  let emailAddress = "";
  let cardType = "";
  let certStatus = "";
  let receiveMethod = "";
  let prdType = "";
  let statusDate = "";

  const getCertiRecord = async (pageNo) => {
    setLoading(true);
    job = await $(".job-form .form-control")
      .map(function () {
        return this.value !== "" ? this.value : null;
      })
      .get()
      .join(",");

    if (job === "" && secureLocalStorage.getItem("jobSearch")) {
      job = secureLocalStorage.getItem("jobSearch");
    } else {
      secureLocalStorage.removeItem("jobSearch");
    }

    secureLocalStorage.removeItem("cert");

    secureLocalStorage.removeItem("customer");

    secureLocalStorage.removeItem("voucher");

    secureLocalStorage.removeItem("denomination");

    secureLocalStorage.removeItem("paNumber");

    secureLocalStorage.removeItem("random_certificate");

    secureLocalStorage.removeItem("clientCost");

    secureLocalStorage.removeItem("begin");

    secureLocalStorage.removeItem("end");

    secureLocalStorage.removeItem("invoiceNo");

    secureLocalStorage.removeItem("redeemDate");

    secureLocalStorage.removeItem("firstName");

    secureLocalStorage.removeItem("lastName");

    secureLocalStorage.removeItem("address");

    secureLocalStorage.removeItem("city");

    secureLocalStorage.removeItem("stateName");

    secureLocalStorage.removeItem("zip");

    secureLocalStorage.removeItem("phone");

    secureLocalStorage.removeItem("emailAddress");

    secureLocalStorage.removeItem("cardType");

    secureLocalStorage.removeItem("certStatus");

    secureLocalStorage.removeItem("receiveMethod");

    secureLocalStorage.removeItem("statusDate");

    secureLocalStorage.removeItem("prdType");

    try {
      const responce = await AdminListService.multiJobSearch(job, pageNo);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
      setShowCertiRecord(true);
      // setJobIds("");
    }
  };

  const getMainCertiRecord = async (pageNo) => {
    setLoading(true);

    if (secureLocalStorage.getItem("jobSearch")) {
      job = secureLocalStorage.getItem("jobSearch");
    }

    if (secureLocalStorage.getItem("cert")) {
      cert = secureLocalStorage.getItem("cert");
    }

    if (secureLocalStorage.getItem("customer")) {
      customer = secureLocalStorage.getItem("customer");
    }

    if (secureLocalStorage.getItem("voucher")) {
      voucher = secureLocalStorage.getItem("voucher");
    }

    if (secureLocalStorage.getItem("denomination")) {
      denomination = secureLocalStorage.getItem("denomination");
    }

    if (secureLocalStorage.getItem("paNumber")) {
      paNumber = secureLocalStorage.getItem("paNumber");
    }

    if (secureLocalStorage.getItem("random_certificate")) {
      random_certificate = secureLocalStorage.getItem("random_certificate");
    }

    if (secureLocalStorage.getItem("clientCost")) {
      clientCost = secureLocalStorage.getItem("clientCost");
    }

    if (secureLocalStorage.getItem("begin")) {
      begin = secureLocalStorage.getItem("begin");
    }

    if (secureLocalStorage.getItem("end")) {
      end = secureLocalStorage.getItem("end");
    }

    if (secureLocalStorage.getItem("invoiceNo")) {
      invoiceNo = secureLocalStorage.getItem("invoiceNo");
    }

    if (secureLocalStorage.getItem("redeemDate")) {
      redeemDate = secureLocalStorage.getItem("redeemDate");
    }

    if (secureLocalStorage.getItem("firstName")) {
      firstName = secureLocalStorage.getItem("firstName");
    }

    if (secureLocalStorage.getItem("lastName")) {
      lastName = secureLocalStorage.getItem("lastName");
    }

    if (secureLocalStorage.getItem("address")) {
      address = secureLocalStorage.getItem("address");
    }

    if (secureLocalStorage.getItem("city")) {
      city = secureLocalStorage.getItem("city");
    }
    if (secureLocalStorage.getItem("stateName")) {
      stateName = secureLocalStorage.getItem("stateName");
    }
    if (secureLocalStorage.getItem("zip")) {
      zip = secureLocalStorage.getItem("zip");
    }
    if (secureLocalStorage.getItem("phone")) {
      phone = secureLocalStorage.getItem("phone");
    }
    if (secureLocalStorage.getItem("emailAddress")) {
      emailAddress = secureLocalStorage.getItem("emailAddress");
    }
    if (secureLocalStorage.getItem("cardType")) {
      cardType = secureLocalStorage.getItem("cardType");
    }
    if (secureLocalStorage.getItem("certStatus")) {
      certStatus = secureLocalStorage.getItem("certStatus");
    }
    if (secureLocalStorage.getItem("receiveMethod")) {
      receiveMethod = secureLocalStorage.getItem("receiveMethod");
    }

    if (secureLocalStorage.getItem("statusDate")) {
      statusDate = secureLocalStorage.getItem("statusDate");
    }
    if (secureLocalStorage.getItem("prdType")) {
      prdType = secureLocalStorage.getItem("prdType");
    }

    try {
      let object = {
        cert,
        vouchNo: voucher,
        redemSt: begin,
        redemEd: end,
        jobNo: job,
        cardType,
        phone,
        certiStatus: certStatus,
        custName: customer,
        fName: firstName,
        lName: lastName,
        address,
        city,
        state: stateName,
        zipCode: zip,
        denom: denomination,
        recMeth: receiveMethod,
        email: emailAddress,
        paNo: paNumber,
        invcNo: invoiceNo,
        clCost: clientCost,
        RedeemedOnDate: redeemDate,
        randNo: random_certificate,
        statusDate,
        prdType,
        certiNo: cert,
      };

      const responce = await AdminListService.searchCertRecord(object, pageNo);
      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
      setShowCertiRecord(true);
      // setJobIds("");
    }
  };

  useEffect(() => {
    if (
      secureLocalStorage.getItem("jobSearch") ||
      secureLocalStorage.getItem("cert") ||
      secureLocalStorage.getItem("customer") ||
      secureLocalStorage.getItem("voucher") ||
      secureLocalStorage.getItem("denomination") ||
      secureLocalStorage.getItem("paNumber") ||
      secureLocalStorage.getItem("random_certificate") ||
      secureLocalStorage.getItem("clientCost") ||
      secureLocalStorage.getItem("begin") ||
      secureLocalStorage.getItem("end") ||
      secureLocalStorage.getItem("invoiceNo") ||
      secureLocalStorage.getItem("redeemDate") ||
      secureLocalStorage.getItem("firstName") ||
      secureLocalStorage.getItem("lastName") ||
      secureLocalStorage.getItem("address") ||
      secureLocalStorage.getItem("city") ||
      secureLocalStorage.getItem("stateName") ||
      secureLocalStorage.getItem("zip") ||
      secureLocalStorage.getItem("phone") ||
      secureLocalStorage.getItem("emailAddress") ||
      secureLocalStorage.getItem("cardType") ||
      secureLocalStorage.getItem("certStatus") ||
      secureLocalStorage.getItem("receiveMethod") ||
      secureLocalStorage.getItem("statusDate") ||
      secureLocalStorage.getItem("prdType")
    ) {
      getMainCertiRecord(currentPage);
    }

    // eslint-disable-next-line
  }, []);

  const sendMailUserById = async (id) => {
    setLoading(true);
    try {
      const responce = await AdminListService.sendMailById(id);

      if (responce.status === 200) {
        setLoading(false);
      }

      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Email sent successfully",
      }));
    } catch (err) {
      console.log(err);
      setLoading(false);
      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Error in sending email",
      }));
    }
  };

  const customSearchRecord = async (e) => {
    setLoading(true);
    const key = await selectKey.current.value;
    const keyVal = e.target.value;
    try {
      const responce = await AdminListService.searchCustomRecord(key, keyVal);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const changeAllRecords = async () => {
    setLoading(true);
    const status = await statusKey.current.value;
    const recordIds = await CertifecateRecordData.map((record) => record.id);
    if (recordIds.length > 0) {
      if (status === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Please select status!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        const recordIdsString = recordIds.join(",");
        try {
          const responce = await AdminListService.allRecordChange(
            pavlina_id,
            status,
            recordIdsString
          );

          if (responce.status === 200) {
            setLoading(false);
            getCertiRecord(currentPage);
          }
        } catch (err) {
          console.log(err);

          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "No record avaliable!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const deActivate = async (id) => {
    setLoading(true);

    try {
      const responce = await AdminListService.deActivateVoucher(id);

      if (responce.status === 200) {
        setLoading(false);
        getCertiRecord(currentPage);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  const CertificateSearchRecords = ({ allData }) => {
    return (
      <>
        {allData.length ? (
          <>
            <div className="table-responsive mt-3">
              <table className="table table-striped table-bordered W-100  align-middle">
                <thead>
                  <tr>
                    <th>User Info</th>
                    <th>Certificate Info</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          First Name: <strong> {el.FirstName} </strong>{" "}
                        </div>
                        <div>
                          Last Name: <strong> {el.LastName} </strong>
                        </div>
                        <div>
                          Phone Number: <strong> {el.PhoneNumber} </strong>
                        </div>
                        <div>
                          Email: <strong> {el.Email} </strong>
                        </div>
                        <div>
                          Address: <strong> {el.Address} </strong>
                        </div>
                        <div>
                          City: <strong> {el.City} </strong>
                        </div>
                        <div>
                          State: <strong> {el.State} </strong>
                        </div>
                        <div>
                          Zip: <strong> {el.ZipCode} </strong>
                        </div>
                        <div>
                          Denomination: <strong> {el.Denomination} </strong>
                        </div>
                        <div>
                          Company: <strong> {el.company} </strong>
                        </div>
                      </td>
                      <td>
                        <div>
                          Cert Number: <strong> {el.certNo} </strong>
                        </div>
                        <div>
                          Random Certificate: <strong> {el.randomCert} </strong>
                        </div>
                        <div>
                          Begin Redemption: <strong> {el.redBegin} </strong>
                        </div>
                        <div>
                          End Redemption: <strong>{el.redEnd} </strong>
                        </div>
                        <div>
                          Job Number: <strong>{el.jobNo} </strong>
                        </div>
                        <div>
                          Cert Status: <strong> {el.certStatus} </strong>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            title="Edit"
                            onClick={() => {
                              secureLocalStorage.setItem("certiEdit", el.id);
                              navigate("/searchcerti_pav");
                            }}
                          >
                            <i className="bi bi-pen-fill"></i> Edit
                          </button>
                          {el.status === "Filled" ? null : (
                            <button
                              type="button"
                              className={
                                el.status === "-1"
                                  ? "btn btn-sm btn-outline-primary"
                                  : "btn btn-sm btn-outline-danger"
                              }
                              title="De Activate"
                              onClick={() => {
                                deActivate(el.id);
                              }}
                            >
                              {el.status === "-1" ? (
                                <>
                                  <i className="bi bi-check-square-fill"></i>{" "}
                                  Activate
                                </>
                              ) : (
                                <>
                                  <i className="bi bi-archive-fill"></i> De
                                  Activate
                                </>
                              )}
                            </button>
                          )}
                          {el.certStatus === "Active" ||
                          el.certStatus === "Voucher Mailed" ? (
                            !emailStatus[el.id] ? (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                title="Edit"
                                onClick={() => {
                                  sendMailUserById(el.id);
                                }}
                              >
                                <i className="bi bi-envelope-fill"></i> Send
                                Email
                              </button>
                            ) : (
                              <strong>{emailStatus[el.id]}</strong>
                            )
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalResults > limit && totalPages > 1 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={parseInt(limit)}
                  totalItemsCount={totalResults}
                  className="justify-content-center"
                  onChange={(e) => {
                    if (
                      secureLocalStorage.getItem("jobSearch") ||
                      secureLocalStorage.getItem("cert") ||
                      secureLocalStorage.getItem("customer") ||
                      secureLocalStorage.getItem("voucher") ||
                      secureLocalStorage.getItem("denomination") ||
                      secureLocalStorage.getItem("paNumber") ||
                      secureLocalStorage.getItem("random_certificate") ||
                      secureLocalStorage.getItem("clientCost") ||
                      secureLocalStorage.getItem("begin") ||
                      secureLocalStorage.getItem("end") ||
                      secureLocalStorage.getItem("invoiceNo") ||
                      secureLocalStorage.getItem("redeemDate") ||
                      secureLocalStorage.getItem("firstName") ||
                      secureLocalStorage.getItem("lastName") ||
                      secureLocalStorage.getItem("address") ||
                      secureLocalStorage.getItem("city") ||
                      secureLocalStorage.getItem("stateName") ||
                      secureLocalStorage.getItem("zip") ||
                      secureLocalStorage.getItem("phone") ||
                      secureLocalStorage.getItem("emailAddress") ||
                      secureLocalStorage.getItem("cardType") ||
                      secureLocalStorage.getItem("certStatus") ||
                      secureLocalStorage.getItem("receiveMethod") ||
                      secureLocalStorage.getItem("statusDate") ||
                      secureLocalStorage.getItem("prdType")
                    ) {
                      getMainCertiRecord(e);
                    } else getCertiRecord(e);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Page"
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <h4 className="text-danger">No data found.</h4>
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <Helmet>
        <title>
          {" "}
          {process.env.REACT_APP_SITE_NAME} - Representative Login Page
        </title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">Search Multiple Job</h5>
          </div>

          <div className="card-body">
            <form className="row g-3 job-form">
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");

                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job Number"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getCertiRecord("1");
                      secureLocalStorage.removeItem("jobSearch");
                    }
                  }}
                />
              </div>

              <div className="col-12 add-space-btns">
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  id="search_certi"
                  onClick={() => {
                    getCertiRecord("1");
                    secureLocalStorage.removeItem("jobSearch");
                  }}
                >
                  Search Certificate Record
                </button>
                <Link to="/searchcerti_pav" className="btn btn-primary mt-2">
                  Reset Form
                </Link>
                <Link to="/searchcomp_pav" className="btn btn-primary mt-2">
                  Mail Merge
                </Link>
                <Link
                  to="/searchmail_pav_hard"
                  className="btn btn-primary mt-2"
                >
                  Card to be Mailed
                </Link>
              </div>
            </form>
          </div>
        </div>

        {showCertiRecord ? (
          <div className="card search-certificate-result mt-3">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Certificate</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="input-group mt-2 mb-2">
                    <label className="search-certificate-label">
                      Change Certificate Status
                    </label>

                    <select
                      className="form-select  dark-border"
                      ref={statusKey}
                    >
                      <option value="" style={{ backgroundColor: "gray" }}>
                        STATUS
                      </option>
                      <option
                        value="Active"
                        style={{ backgroundColor: "green" }}
                      >
                        Active
                      </option>
                      <option
                        value="Voucher Mailed"
                        style={{ backgroundColor: "purple" }}
                      >
                        Voucher Mailed
                      </option>
                      <option
                        value="Voucher Received"
                        style={{ backgroundColor: "SandyBrown" }}
                      >
                        Voucher Received
                      </option>
                      <option
                        value="Card Ordered"
                        style={{ backgroundColor: "pink" }}
                      >
                        Card Ordered
                      </option>
                      <option
                        value="Card Mailed"
                        style={{ backgroundColor: "SkyBlue" }}
                      >
                        Card Mailed
                      </option>
                      <option
                        value="Expired"
                        style={{ backgroundColor: "Salmon" }}
                      >
                        Expired
                      </option>
                      <option value="Void" style={{ backgroundColor: "red" }}>
                        Void
                      </option>
                    </select>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={changeAllRecords}
                    >
                      Change All Record
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6 text-end">
                  {job !== "" ? (
                    <a
                      href={
                        process.env.REACT_APP_API_Link + "exportMultiJob/" + job
                      }
                      className="btn btn-primary mt-2 mb-2"
                    >
                      Export As Excel
                    </a>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 col-lg-4 ms-auto">
                  <div className="input-group mt-2 mb-2">
                    <select className="form-select" ref={selectKey}>
                      <option value="all">All</option>
                      <option value="FIRST_NAME">First Name</option>
                      <option value="LAST_NAME">Last Name</option>
                      <option value="PHONE_NUMBER">Phone Number</option>
                      <option value="EmailAddress">Email</option>
                      <option value="ADDRESS">Address</option>
                      <option value="CITY">City</option>
                      <option value="STATE">State</option>
                      <option value="ZIP">Zip</option>
                      <option value="DENOMINATION">Denomination</option>
                      <option value="CUSTOMER">Company</option>
                      <option value="CERT_NUMBER">Cert Number</option>
                      <option value="REDEMPTION_BEINS">Begin Redemption</option>
                      <option value="REDEMPTION_ENDS">End Redemption</option>
                      <option value="JOB_NUMBER">Job Number</option>
                      <option value="CERT_STATUS">Cert Status</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={customSearchRecord}
                    />
                  </div>
                </div>
              </div>
              <CertificateSearchRecords allData={CertifecateRecordData} />
            </div>
          </div>
        ) : null}
        {/* <div className="card search-certificate-result">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">Certificate</h5>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <div className="input-group mt-2 mb-2">
                  <label className="search-certificate-label">
                    Change Certificate Status
                  </label>

                  <select className="form-select status-dropdown">
                    <option>Status</option>
                    <option>ab</option>
                    <option>cd</option>
                    <option>ef</option>
                  </select>
                  <button type="button" className="btn btn-primary">
                    Change All Reord
                  </button>
                </div>
              </div>
              <div className="col-md-4 col-lg-6 text-end">
                <Link className="btn btn-primary mt-2 mb-2">
                  Export As Excel
                </Link>
              </div>
            </div>

            <div className="table-responsive mt-3">
              <table
                id="example"
                className="table table-striped table-bordered dataTable no-footer "
              >
                <thead>
                  <tr>
                    <th>User Info</th>
                    <th>Certificate Info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        First Name: <strong> test </strong>{" "}
                      </div>
                      <div>
                        Last Name: <strong> test </strong>
                      </div>
                      <div>
                        Phone Number: <strong> test </strong>
                      </div>
                      <div>
                        Email: <strong> test </strong>
                      </div>
                      <div>
                        Address: <strong> test </strong>
                      </div>
                      <div>
                        City: <strong> test </strong>
                      </div>
                      <div>
                        State: <strong> test </strong>
                      </div>
                      <div>
                        Zip: <strong> test </strong>
                      </div>
                      <div>
                        Denomination: <strong> test </strong>
                      </div>
                      <div>
                        Company: <strong> test </strong>
                      </div>
                    </td>
                    <td>
                      <div>
                        Cert Number: <strong> test </strong>
                      </div>
                      <div>
                        Random Certificate: <strong> 2321 </strong>
                      </div>
                      <div>
                        Begin Redemption: <strong> 04-20-2020 </strong>
                      </div>
                      <div>
                        End Redemption: <strong> 04-20-2021 </strong>
                      </div>
                      <div>
                        Job Number: <strong> test </strong>
                      </div>
                      <div>
                        Cert Status: <strong> Card Mailed </strong>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        First Name: <strong> test </strong>{" "}
                      </div>
                      <div>
                        Last Name: <strong> test </strong>
                      </div>
                      <div>
                        Phone Number: <strong> test </strong>
                      </div>
                      <div>
                        Email: <strong> test </strong>
                      </div>
                      <div>
                        Address: <strong> test </strong>
                      </div>
                      <div>
                        City: <strong> test </strong>
                      </div>
                      <div>
                        State: <strong> test </strong>
                      </div>
                      <div>
                        Zip: <strong> test </strong>
                      </div>
                      <div>
                        Denomination: <strong> test </strong>
                      </div>
                      <div>
                        Company: <strong> test </strong>
                      </div>
                    </td>
                    <td>
                      <div>
                        Cert Number: <strong> test </strong>
                      </div>
                      <div>
                        Random Certificate: <strong> 2321 </strong>
                      </div>
                      <div>
                        Begin Redemption: <strong> 04-20-2020 </strong>
                      </div>
                      <div>
                        End Redemption: <strong> 04-20-2021 </strong>
                      </div>
                      <div>
                        Job Number: <strong> test </strong>
                      </div>
                      <div>
                        Cert Status: <strong> Card Mailed </strong>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </main>

      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
