import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { randomCerti } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import { useSelector } from "react-redux";

export default function RandomCert() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);

  const siteList = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.allSites();

      if (responce.status === 200) {
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            SiteId: value.SiteId,
            SiteName: value.SiteName,
            random_certificate: value.random_certificate,
          });
        });

        settableData([...results]);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      settableData([]);
    }
  };
  useEffect(() => {
    if (adminAccessToken) {
      siteList();
    }
  }, [adminAccessToken]);

  const siteRandomCerti = async (event, siteId) => {
    setLoading(true);
    let status;

    if (event.target.checked) {
      status = 0;
    } else {
      status = 1;
    }

    try {
      const responce = await AdminListService.siteStatusUpdate(status, siteId);

      if (responce.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Status Changed Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        siteList();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Random certificate not updated.",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const Table = ({ data }) => {
    return (
      <>
        {data.length ? (
          <div className="row g-3 sites-list">
            {data.map((el, index) => (
              <div className="col-12 col-md-3 col-lg-3" key={index}>
                <label htmlFor={el.SiteId}>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    value={el.SiteId}
                    id={el.SiteId}
                    onChange={(e) => {
                      siteRandomCerti(e, el.SiteId);
                    }}
                    defaultChecked={
                      el.random_certificate === "0" ? true : false
                    }
                  />{" "}
                  {el.SiteName}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-capitalize text-de">
            No record found
          </div>
        )}
      </>
    );
  };

  const sitesCertificatesStatus = async (e) => {
    setLoading(true);

    try {
      const responce = await AdminListService.allSitesCertificatesStatus(e);

      if (responce.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Status Changed Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        siteList();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Random certificate not updated.",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const randomGenerate = async (values) => {
    setLoading(true);

    try {
      const responce = await AdminListService.updateRandomCertificates(values);

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Random certificate updated successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        certiLength: "7",
        Prefix: "",
        Uppercase: true,
        Lowercase: false,
        Digits: true,
        Special: false,
        Punctuation: false,
        Brackets: false,
      },
      validationSchema: randomCerti,
      onSubmit: (values) => {
        randomGenerate(values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Ceritificate</h5>
            </div>

            <div className="card-body">
              <button
                type="button"
                className="btn btn-primary btn-sm float-end ms-2"
                onClick={() => {
                  sitesCertificatesStatus(1);
                }}
              >
                <i className="bi bi-x-circle-fill"></i> Disable for all
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm float-end ms-2"
                onClick={() => {
                  sitesCertificatesStatus(0);
                }}
              >
                <i className="bi bi-plus-circle-fill"></i> Enable for all
              </button>
              <div className="clearfix"></div>

              <h2 className="comp-name">Company Name</h2>
              <hr />

              <form onSubmit={handleSubmit} noValidate>
                <Table data={tableData} />

                <hr />
                <div className="row g-3 use-characterset">
                  <div className="col-12 col-md-6 col-lg-4">
                    <label className="form-label">Certificate length </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Certificate length"
                      name="certiLength"
                      value={values.certiLength || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.certiLength && touched.certiLength ? (
                      <span className="text-danger">{errors.certiLength}</span>
                    ) : null}

                    <br />
                    <br />
                    <label className="form-label">Prefix </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prefix"
                      name="Prefix"
                      value={values.Prefix || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.Prefix && touched.Prefix ? (
                      <span className="text-danger">{errors.Prefix}</span>
                    ) : null}
                  </div>

                  <div className="col-12 col-md-6 col-lg-4  ps-md-4 ps-lg-5">
                    <label className="form-label">Charactersets to use </label>
                    <br />

                    <label htmlFor="Uppercase" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Uppercase"
                        checked={values.Uppercase}
                        onChange={handleChange}
                      />
                      Uppercase (A,B,C,...)
                    </label>

                    <label htmlFor="Lowercase" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Lowercase"
                        checked={values.Lowercase}
                        onChange={handleChange}
                      />
                      Lowercase (a, b, c, ...)
                    </label>

                    <label htmlFor="Digits" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Digits"
                        checked={values.Digits}
                        onChange={handleChange}
                      />
                      Digits (0, 1, 2, ...)
                    </label>

                    <label htmlFor="characters" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Special"
                        checked={values.Special}
                        onChange={handleChange}
                      />
                      Special characters ($, %, &, ...)
                    </label>

                    <label htmlFor="Punctuation" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Punctuation"
                        checked={values.Punctuation}
                        onChange={handleChange}
                      />
                      Punctuation (!, ?, ...)
                    </label>
                    <label htmlFor="Brackets" className="d-block my-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="Brackets"
                        checked={values.Brackets}
                        onChange={handleChange}
                      />
                      Brackets ([, ], ...)
                    </label>
                  </div>

                  <hr />
                  <div className="col-md-4 m-0">
                    <div className="d-flex h-100 w-100 align-items-end mb-mob">
                      <button className="btn btn-primary w-100">
                        Save Option
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
