import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
function Model(props) {
  const [show, setShow] = useState(false);

  const handleModal = (e) => {
    setShow(!show);
  };
  return (
    <div>
      <div>
        <div className="modalClass">
          <Button id="pop" onClick={handleModal}>
            terms and conditions
          </Button>
        </div>

        <Modal
          show={show}
          size="lg"
          onHide={handleModal}
          dialogClassName="term-model"
          centered
        >
          <Modal.Header>
            <h4 className="modal-title" id="myModalLabel">
              {" "}
              Reward Voucher Terms and Conditions{" "}
            </h4>
            <Button id="pop-close" onClick={handleModal}>
              &times;
            </Button>
          </Modal.Header>
          <div className="model-body">
            <ul className="terms-list">
              <li key="1">
                Elite Rewards reserves the right to substitute a comparable
                rewards card.{" "}
              </li>
              <li key="2">
                {" "}
                Only one voucher can be redeemed per household per month.{" "}
              </li>
              <li key="3">
                {" "}
                All taxes are the responsibility of the person receiving the
                redemption.
              </li>
              <li key="4">
                {" "}
                Vouchers are transferable but cannot be resold. Notice of resell
                will void the voucher.{" "}
              </li>
              <li key="4">
                {" "}
                Once your voucher is received, allow 2-4 weeks for processing
                and delivery. The rewards card you selected will be sent to you
                via the US Postal Service or by email where virtual-cards are
                available. Failure to adhere to all Terms &amp; Conditions may
                hinder the processing of your voucher.{" "}
              </li>
              <li key="5">
                {" "}
                {process.env.REACT_APP_SITENAME} promotion and advertisements
                are produced and distributed by {process.env.REACT_APP_SITENAME}
                , an independently owned and operated business. Elite Rewards is
                only responsible for providing the product to support the
                promotion. Delivery is included with the promotion. However,
                installation is not (if applicable).{" "}
              </li>
              <li key="6"> Offer not valid after expiration date.</li>
              <li key="7"> No duplicates of this voucher will be accepted. </li>
              <li key="8">
                {" "}
                Failure to complete any line of this form in clear printed text
                will result in this voucher being returned to you and will cause
                a delay in processing.{" "}
              </li>
              <li key="9">
                {" "}
                You have received this voucher as part of a business promotion.{" "}
              </li>
              <li key="10">
                {" "}
                The business promoter who granted the voucher to you
                specifically conditions its redemption upon full payment to
                Elite Rewards.{" "}
              </li>
            </ul>
          </div>
          <Modal.Footer>
            <Button id="btn1" onClick={handleModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Model;
