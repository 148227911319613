import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { osrAdd } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function AddOsr() {
  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const addOsr = async (values) => {
    setLoading(true);

    try {
      const responce = await AdminListService.osrUserAdd(values, admin_id);

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Added Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/osr_manage");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        osrName: "",
        userName: "",
        password: "",
        email: "",
      },
      validationSchema: osrAdd,
      onSubmit: (values) => {
        addOsr(values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Add OSR</h5>
            </div>

            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-3">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="userName"
                    value={values.userName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.userName && touched.userName ? (
                    <span className="text-danger">{errors.userName}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-3">
                  <label className="form-label">Password </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-3">
                  <label className="form-label">OSR Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Site Name"
                    name="osrName"
                    value={values.osrName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.osrName && touched.osrName ? (
                    <span className="text-danger">{errors.osrName}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-3">
                  <label className="form-label">OSR Email </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-3">
                  <button className="btn btn-primary w-100">
                    Save OSR Record
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
