import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";

import ToTop from "../includes/ToTop";
import { useSelector } from "react-redux";

export default function MailMerge() {
  const state = useSelector((state) => state.stateVals);

  const { pavlina_id } = state;
  let navigate = useNavigate();
  const [adminCards, setAdminCards] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [loading, setLoading] = useState(false);

  const company = useRef(null);
  const card = useRef(null);
  const selectKey = useRef(null);

  const [showCertiRecord, setShowCertiRecord] = useState(false);
  const [CertifecateRecordData, setCertifecateRecordData] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");

  useEffect(() => {
    const getCardsAdmin = async () => {
      try {
        const responce = await AdminListService.getAdminCards();

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.CardId,
            name: value.CardName,
          });
        });
        setAdminCards([...results]);
      } catch (err) {
        console.log(err);
        setAdminCards([]);
      }
    };

    const getCompany = async () => {
      try {
        const responce = await AdminListService.getCompanyList();

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.CUSTOMER,
            name: value.CUSTOMER,
          });
        });
        setListCompany([...results]);
      } catch (err) {
        console.log(err);
        setListCompany([]);
      }
    };
    if (pavlina_id) {
      getCompany();
      getCardsAdmin();
    }
  }, [pavlina_id]);

  const searchMerge = async () => {
    setLoading(true);
    const selectedComp = await company.current.value;
    const selectedCard = await card.current.value;
    try {
      const responce = await AdminListService.mergeMailSearch(
        selectedComp,
        selectedCard,
        "1"
      );

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
      setShowCertiRecord(true);
    }
  };

  const customSearchRecord = async (e) => {
    setLoading(true);
    const key = await selectKey.current.value;
    const keyVal = e.target.value;
    try {
      const responce = await AdminListService.searchCustomRecord(key, keyVal);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const CertificateSearchRecords = ({ allData }) => {
    return (
      <>
        {allData.length ? (
          <>
            <div className="row">
              <div className="col-md-8 col-lg-6"></div>
              <div className="col-md-4 col-lg-6 text-end">
                <a
                  href={
                    process.env.REACT_APP_API_Link +
                    "exportMailMerge/" +
                    company.current.value +
                    "/" +
                    card.current.value
                  }
                  className="btn btn-primary mt-2 mb-2"
                >
                  Export As Excel
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 col-lg-4 ms-auto">
                <div className="input-group mt-2 mb-2">
                  <select className="form-select" ref={selectKey}>
                    <option value="all">All</option>
                    <option value="FIRST_NAME">First Name</option>
                    <option value="LAST_NAME">Last Name</option>
                    <option value="PHONE_NUMBER">Phone Number</option>
                    <option value="EmailAddress">Email</option>
                    <option value="ADDRESS">Address</option>
                    <option value="CITY">City</option>
                    <option value="STATE">State</option>
                    <option value="ZIP">Zip</option>
                    <option value="DENOMINATION">Denomination</option>
                    <option value="CUSTOMER">Company</option>
                    <option value="CERT_NUMBER">Cert Number</option>
                    <option value="REDEMPTION_BEINS">Begin Redemption</option>
                    <option value="REDEMPTION_ENDS">End Redemption</option>
                    <option value="JOB_NUMBER">Job Number</option>
                    <option value="CERT_STATUS">Cert Status</option>
                  </select>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={customSearchRecord}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive mt-3">
              <table className="table table-striped table-bordered W-100  align-middle">
                <thead>
                  <tr>
                    <th>User Info</th>
                    <th>Certificate Info</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          First Name: <strong> {el.FirstName} </strong>{" "}
                        </div>
                        <div>
                          Last Name: <strong> {el.LastName} </strong>
                        </div>
                        <div>
                          Phone Number: <strong> {el.PhoneNumber} </strong>
                        </div>
                        <div>
                          Email: <strong> {el.Email} </strong>
                        </div>
                        <div>
                          Address: <strong> {el.Address} </strong>
                        </div>
                        <div>
                          City: <strong> {el.City} </strong>
                        </div>
                        <div>
                          State: <strong> {el.State} </strong>
                        </div>
                        <div>
                          Zip: <strong> {el.ZipCode} </strong>
                        </div>
                        <div>
                          Denomination: <strong> {el.Denomination} </strong>
                        </div>
                        <div>
                          Company: <strong> {el.company} </strong>
                        </div>
                      </td>
                      <td>
                        <div>
                          Cert Number: <strong> {el.certNo} </strong>
                        </div>
                        <div>
                          Random Certificate: <strong> {el.randomCert} </strong>
                        </div>
                        <div>
                          Begin Redemption: <strong> {el.redBegin} </strong>
                        </div>
                        <div>
                          End Redemption: <strong>{el.redEnd} </strong>
                        </div>
                        <div>
                          Job Number: <strong>{el.jobNo} </strong>
                        </div>
                        <div>
                          Cert Status: <strong> {el.certStatus} </strong>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalResults > limit && totalPages > 1 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={parseInt(limit)}
                  totalItemsCount={totalResults}
                  className="justify-content-center"
                  onChange={(e) => {
                    searchMerge(e);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Page"
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <h4 className="text-danger">No data found.</h4>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} Pavlina Admin</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">Select the Company</h5>
          </div>

          <div className="card-body">
            <form className="row g-3">
              <div className="col-12 col-md-3 col-lg-3">
                <label className="form-label">Company name</label>
                <select className="form-select" ref={company}>
                  <option value="">Company</option>
                  {listCompany.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-12 col-md-3 col-lg-3">
                <label className="form-label">Card name</label>
                <select className="form-select" ref={card}>
                  <option value="">Select Card</option>
                  {adminCards.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-12 add-space-btns">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={searchMerge}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>

        {showCertiRecord ? (
          <div className="card search-certificate-result mt-3">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Certificate</h5>
            </div>

            <div className="card-body">
              <CertificateSearchRecords allData={CertifecateRecordData} />
            </div>
          </div>
        ) : null}
      </main>
      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
