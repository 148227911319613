import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import { useSelector } from "react-redux";

export default function ManageByCompanies() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const cardList = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listCardByCompany(page);
      console.log(responce);
      let res;

      if (responce.status === 200) {
        //

        // const res = responce.data.data.data;
        if (Array.isArray(responce?.data?.data?.data)) {
          res = responce?.data?.data?.data;
        } else if (typeof responce?.data?.data?.data === "object") {
          res = Object.values(responce.data.data.data);
        }
        const results = [];
        res.map((value) => {
          return results.push({
            customer: value.customer,
            card: value.card,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        // setLimit(responce.data.data.per_page);
        // setTotalResults(responce.data.data.total_records);
        // setTotalPages(responce.data.data.total_pages);
        // setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardList("1");
    }
  }, [adminAccessToken]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.customer.toString().toLowerCase().includes(searchQuery) ||
          item.card.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 align-middle">
          <thead>
            <tr>
              <th>Company Names</th>
              <th>Job Cards</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {slice.length ? (
              slice.map((el, index) => (
                <tr key={index}>
                  <td>{el.customer}</td>
                  <td>{el.card}</td>
                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="btn btn-sm btn-primary"
                        to={`/job_add_by_company?p=${el.customer}`}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={rowsPerPage}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Cards By Companies</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
