import React from "react";
import ToTop from "./ToTop";

export default function Footer() {
  return (
    <>
      <footer className="site-footer-main">
        Copyright {process.env.REACT_APP_API_SITE_TITLE}{" "}
        {new Date().getFullYear()}. All Rights Reserved.
      </footer>
      <ToTop />
    </>
  );
}
