import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { addCard } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function EditCard() {
  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;

  const [file, setFile] = useState("");

  let navigate = useNavigate();

  const [fileError, setFileError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (admin_id) {
      const getCard = async () => {
        setLoading(true);
        try {
          const responce = await AdminListService.listCardById(id);
          if (responce.status === 200) {
            setLoading(false);
            await setValues({
              name: responce.data.data?.CardName,
            });
          }
        } catch (err) {
          console.log(err);

          setLoading(false);
          if (err?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: "Some thing went wrong!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      };

      getCard();
    }

    // eslint-disable-next-line
  }, []);

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    setFileError(false);
  };

  const cardUpdate = async (values) => {
    if (file === "") {
      setFileError(true);
    } else {
      setFileError(false);

      setLoading(true);
      const formData = new FormData();
      formData.append("CardImage", file);
      formData.append("adminId", admin_id);
      formData.append("CardName", values.name);

      try {
        const responce = await AdminListService.updateCard(formData, id);

        if (responce.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            navigate("/card_manage");
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: addCard,
    onSubmit: (values) => {
      cardUpdate(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Edit Card</h5>
            </div>

            <div className="card-body">
              <Link
                to="/admin_dashboard"
                className="btn btn-primary mt-2 btn-sm float-lg-end"
              >
                {" "}
                Dashboard
              </Link>

              <Link to="/card_manage" className="btn btn-primary mt-2 btn-sm">
                Manage Cards
              </Link>

              <div className="clearfix"></div>

              <form className="row g-3 mt-2" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-4">
                  <label className="form-label">Card Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Card Name"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <span className="text-danger">{errors.name}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-4">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Card Image
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFileMultiple"
                    accept=".png,.jpg,.webp"
                    onClick={(e) => {
                      e.target.value = null;
                      setFile("");
                    }}
                    onChange={handleUpload}
                  />
                  {fileError ? (
                    <span className="text-danger">Please Select File</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-md-4">
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label invisible"
                  >
                    Card Image
                  </label>
                  <button className="btn btn-primary w-100">
                    Update Card Record
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
