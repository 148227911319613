import React from "react";
import cityLook from "../Images/city_look.png";
function EmailBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityLook} alt="city-look.png" />
      </div>

      {/* <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email(s) within 5 business days from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
          {process.env.REACT_APP_DESC_GIFTCARD}
        </a>{" "}
        or <br />
        <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
          {process.env.REACT_APP_MARKET_PLACE}
        </a>
        .
        <br />
        Please add these email addresses to your safe senders list in your email
        setting.
        <br />
        Once you receive your email(s) you may use the reward virtually or
        request plastic cards to be mailed.
      </div> */}
      <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email(s) within 5 business days from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
          {process.env.REACT_APP_DESC_GIFTCARD}
        </a>
        ,{" "}<br/>
        <a href={"mailto:" + process.env.REACT_APP_DESC_PREP}>
          {process.env.REACT_APP_DESC_PREP}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
          {process.env.REACT_APP_MARKET_PLACE}
        </a>{" "}
        <br />
        Please add these email addresses to your safe senders list in your email
        setting.
        <br />
        Once you receive your email(s) you may use the reward virtually or
        request plastic cards to be mailed.
      </div>
      
    </div>
  );
}

export default EmailBox;
