import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import $ from "jquery";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function SendMail() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const { groupNo } = useParams();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [filteredData, setFilteredData] = useState([]);

  const [emailStatus, setEmailStatus] = useState([]);

  const mailList = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.userForMail(page, groupNo);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      mailList("1");
    }

    // eslint-disable-next-line
  }, []);

  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  };

  const sendMailUserById = async (id) => {
    setLoading(true);
    try {
      const responce = await AdminListService.sendMailById(id);

      if (responce.status === 200) {
        setLoading(false);
      }

      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Email sent successfully",
      }));
    } catch (err) {
      console.log(err);
      setLoading(false);
      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Email not sent successfully",
      }));
    }
  };

  const sendMailUserAll = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.sendMailByGroup(groupNo);

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Send Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const sendMailUserByselect = async () => {
    var selectedRecords = await $("tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Please select some record!",
      });
    } else {
      setLoading(true);
      try {
        const responce = await AdminListService.sendMailToSelected(
          selectedRecords
        );

        if (responce.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Send Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.randomCert.toString().toLowerCase().includes(searchQuery) ||
          item.certStatus.toString().toLowerCase().includes(searchQuery) ||
          item.jobNo.toString().toLowerCase().includes(searchQuery) ||
          item.redEnd.toString().toLowerCase().includes(searchQuery) ||
          item.redBegin.toString().toLowerCase().includes(searchQuery) ||
          item.certNo.toString().toLowerCase().includes(searchQuery) ||
          item.Denomination.toString().toLowerCase().includes(searchQuery) ||
          item.Email.toString().toLowerCase().includes(searchQuery) ||
          item.PhoneNumber.toString().toLowerCase().includes(searchQuery) ||
          item.ZipCode.toString().toLowerCase().includes(searchQuery) ||
          item.State.toString().toLowerCase().includes(searchQuery) ||
          item.City.toString().toLowerCase().includes(searchQuery) ||
          item.Address.toString().toLowerCase().includes(searchQuery) ||
          item.LastName.toString().toLowerCase().includes(searchQuery) ||
          item.FirstName.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths align-middle">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                <input
                  className="form-check-input slect_all"
                  type="checkbox"
                  value=""
                  onChange={allSelect}
                />
              </th>
              <th
                scope="col"
                onClick={() => requestSort("FirstName")}
                className={getClassNamesFor("FirstName")}
              >
                User Info
              </th>
              <th
                scope="col"
                onClick={() => requestSort("certNo")}
                className={getClassNamesFor("certNo")}
              >
                Certificate Info
              </th>
              <th
                scope="col"
                onClick={() => requestSort("certStatus")}
                className={getClassNamesFor("certStatus")}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={el.id}
                      value={el.id}
                    />
                  </td>
                  <td>
                    <div>
                      First Name: <strong> {el.FirstName} </strong>{" "}
                    </div>
                    <div>
                      Last Name: <strong> {el.LastName} </strong>
                    </div>
                    <div>
                      Phone Number: <strong> {el.PhoneNumber} </strong>
                    </div>
                    <div>
                      Email: <strong> {el.Email} </strong>
                    </div>
                    <div>
                      Address: <strong> {el.Address} </strong>
                    </div>
                    <div>
                      City: <strong> {el.City} </strong>
                    </div>
                    <div>
                      State: <strong> {el.State} </strong>
                    </div>
                    <div>
                      Zip: <strong> {el.ZipCode} </strong>
                    </div>
                    <div>
                      Denomination: <strong> {el.Denomination} </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      Cert Number: <strong> {el.certNo} </strong>
                    </div>
                    <div>
                      Random Certificate: <strong> {el.randomCert} </strong>
                    </div>
                    <div>
                      Begin Redemption: <strong> {el.redBegin} </strong>
                    </div>
                    <div>
                      End Redemption: <strong>{el.redEnd} </strong>
                    </div>
                    <div>
                      Job Number: <strong>{el.jobNo} </strong>
                    </div>
                    <div>
                      Cert Status: <strong> {el.certStatus} </strong>
                    </div>
                  </td>
                  <td>
                    {!emailStatus[el.id] ? (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          sendMailUserById(el.id);
                        }}
                      >
                        <i className="bi bi-envelope-fill"></i> Send Mail
                      </button>
                    ) : (
                      <strong>{emailStatus[el.id]}</strong>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary btn-sm "
                onClick={sendMailUserAll}
              >
                <i className="bi bi-envelope-fill"></i> Send Mail To All
              </button>
              <button
                className="btn btn-primary btn-sm ms-2"
                onClick={sendMailUserByselect}
              >
                <i className="bi bi-envelope-fill"></i> Send Mail To Selected
              </button>
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />{" "}
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
