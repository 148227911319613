import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cityLogo from "../Images/logo_12.jpg";

function ContentPopup() {
  const [show, setShow] = useState(true);

  const handleModal = (e) => {
    setShow(!show);
  };

  return (
    <div>
      <div>
        <Modal
          show={show}
          onHide={handleModal}
          id="modelContent"
          size="xl"
          centered
        >
          <Button id="pop-close1" onClick={handleModal}>
            &times;
          </Button>
          <Modal.Header id="header">
            <img id="popImg" src={cityLogo} alt="img-responsive" />
          </Modal.Header>
          <div className="model-body">
            <ul className="llist">
              {/* <li key="1">
                Expect an email(s) within 5 business days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
                  {process.env.REACT_APP_DESC_GIFTCARD}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>
                .
              </li> */}
              <li key="1">
                Expect an email(s) within 5 business days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
                  {process.env.REACT_APP_DESC_GIFTCARD}
                </a>
                ,{" "}<br/>
                <a href={"mailto:" + process.env.REACT_APP_DESC_PREP}>
                  {process.env.REACT_APP_DESC_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>{" "}
              </li>
              <li key="2">
                Please add these email addresses to your safe senders list in
                your email setting.
              </li>
              <li key="3">
                Once you receive your email(s) you may use the reward virtually
                or request plastic cards to be mailed.
              </li>
              <br />
              <p>
                <b>
                  PLEASE NOTE:You only have 60 days to activate your card. Check
                  your inbox, junk and spam and contact our customer service
                  line if you haven't received the email within the week.
                </b>
              </p>
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ContentPopup;
