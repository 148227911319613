import React from "react";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function Dashboard() {
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1">Manage Sites</h4>
                      <Link to="/site_manage" className="manage-btns">
                        Manage Customers
                      </Link>
                      <Link to="/site_add" className="manage-btns">
                        Add New Site
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-person-plus-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1"> Manage Cards</h4>
                      <Link to="/card_manage" className="manage-btns">
                        Manage Cards
                      </Link>
                      <Link to="/card_add" className="manage-btns">
                        Add New Cards
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-sd-card-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1"> Manage Jobs</h4>
                      <Link to="/job_manage" className="manage-btns">
                        Manage Jobs
                      </Link>
                      <Link to="/job_add" className="manage-btns">
                        Add New Jobs
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-card-checklist"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1"> Manage OSR</h4>
                      <Link to="/osr_manage" className="manage-btns">
                        Manage OSR
                      </Link>
                      <Link to="/osr_add" className="manage-btns">
                        Add New OSR
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-card-checklist"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1"> Manage Pavlina</h4>
                      <Link to="/Managestatus" className="manage-btns">
                        Manage Admin
                      </Link>
                      <Link to="/Addpav" className="manage-btns">
                        Add New Admin
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-card-checklist"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card radius-10 border-0 border-start border-orange border-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h4 className="mb-1"> Manage Mail</h4>
                      <Link to="/manage_mails" className="manage-btns">
                        Manage Mail
                      </Link>
                    </div>
                    <div className="ms-auto widget-icon bg-orange text-white">
                      <i className="bi bi-card-checklist"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
