import React, { useLayoutEffect, useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import $ from "jquery";
import { useRef } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function EditJob() {
  const state = useSelector((state) => state.stateVals);
  let navigate = useNavigate();
  const { admin_id } = state;

  const { group } = useParams();

  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [tableData, settableData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [JobId, setJobId] = useState("");

  const jobNumber = useRef(null);

  const allCards = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.getAllCard();

      if (responce.status === 200) {
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            CardId: value.CardId,
            CardImage: value.CardImage,
            CardName: value.CardName,
          });
        });

        settableData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
    }
  };

  useLayoutEffect(() => {
    const getJobByGroup = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.jobByGroup(group);

        if (responce.status === 200) {
          setLoading(false);
          jobNumber.current.value = responce.data.data.editData.JobNumber;
          setJobId(responce.data.data.editData.JobId);

          const res = responce.data.data.cardData;
          const cards = [];
          res.map((value) => {
            return cards.push(value.CardId);
          });

          setCardData({ ...cards });
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    };
    if (admin_id) {
      allCards();
      getJobByGroup();
    }
    // eslint-disable-next-line
  }, []);

  const updateJob = async () => {
    var selectedCards = await $(".on-off-cards input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    if (jobNumber.current.value === "") {
      setFieldError(true);
      jobNumber.current.focus();
    } else if (!selectedCards.length) {
      setFieldError(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Please select card!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    } else {
      setFieldError(false);
      setLoading(true);

      try {
        const responce = await AdminListService.jobUpdate(
          group,
          jobNumber.current.value,
          selectedCards,
          admin_id,
          JobId
        );

        if (responce.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            navigate("/job_manage");
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }
  };

  const Cards = ({ data, avalCard }) => {
    const convertedArray = Object.values(avalCard);

    return data.length
      ? data.map((el, index) => (
          <div className="col-12 col-md-4 col-lg-3 text-center" key={index}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={el.CardId}
                value={el.CardId}
                defaultChecked={
                  convertedArray?.includes(el.CardId) ? true : false
                }
              />
              <label className="form-check-label" htmlFor={el.CardId}>
                {el.CardName}

                {el.CardImage !== "" ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_LINK}/resources/images/cards/${el.CardImage}`}
                    className="card_logo_img"
                    alt="card pic"
                  />
                ) : (
                  <div className="pirds pirds2">
                    <span className="rdcrdtop">
                      <table border="0">
                        <tbody>
                          <tr>
                            <td>{el.CardName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <span className="rdcrd">REWARD CARD</span>
                  </div>
                )}
              </label>
            </div>
          </div>
        ))
      : null;
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Edit Job</h5>
            </div>

            <div className="card-body">
              <div className="row g-3">
                <div className="col-12 col-md-12">
                  <label className="form-label">Job Number</label>
                  <input
                    ref={jobNumber}
                    type="number"
                    className="form-control"
                    placeholder="Job Number"
                  />
                  {fieldError && (
                    <span className="text-danger">Please enter job number</span>
                  )}
                </div>

                <div className="on-off-cards">
                  <div className="row">
                    <Cards data={tableData} avalCard={cardData} />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <button className="btn btn-primary w-100" onClick={updateJob}>
                    Save Job Record
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
