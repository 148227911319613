import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";

export default function CertiDetails() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const { status, company } = useParams();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [limit, setLimit] = useState("20");

  const cardDetail = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.cardDetailByStatusCompany(
        status,
        company,
        page
      );

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            JOB_NUMBER: value.JOB_NUMBER,
            CERT_NUMBER: value.CERT_NUMBER,
            CERT_NUMBER_1: value.CERT_NUMBER_1,
            REDEMPTION_BEINS: value.REDEMPTION_BEINS,
            REDEMPTION_ENDS: value.REDEMPTION_ENDS,
            CERT_STATUS: value.CERT_STATUS,
            FIRST_NAME: value.FIRST_NAME,
            LAST_NAME: value.LAST_NAME,
            ADDRESS: value.ADDRESS,
            CITY: value.CITY,
            STATE: value.STATE,
            ZIP: value.ZIP,
            PHONE_NUMBER: value.PHONE_NUMBER,
            EmailAddress: value.EmailAddress,
            DENOMINATION: value.DENOMINATION,
            RECEIVED_METHOD: value.RECEIVED_METHOD,
            MAILED_DATE: value.MAILED_DATE,
            Redeemed_On_Date: value.Redeemed_On_Date,
            Mailed: value.Mailed,
            status_date: value.status_date,
            Agent: value.Agent,
            AgentDateTime: value.AgentDateTime,
            UNIQUE_ID: value.UNIQUE_ID,
            CARD_ORDERED: value.CARD_ORDERED,
            FILE_NOTES: value.FILE_NOTES,
            CODE: value.CODE,
            Campaign: value.Campaign,
            CampaignIDNo: value.CampaignIDNo,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardDetail("1");
    }

    // eslint-disable-next-line
  }, []);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.CampaignIDNo.toString().toLowerCase().includes(searchQuery) ||
          item.Campaign.toString().toLowerCase().includes(searchQuery) ||
          item.CODE.toString().toLowerCase().includes(searchQuery) ||
          item.FILE_NOTES.toString().toLowerCase().includes(searchQuery) ||
          item.CARD_ORDERED.toString().toLowerCase().includes(searchQuery) ||
          item.UNIQUE_ID.toString().toLowerCase().includes(searchQuery) ||
          item.AgentDateTime.toString().toLowerCase().includes(searchQuery) ||
          item.Agent.toString().toLowerCase().includes(searchQuery) ||
          item.status_date.toString().toLowerCase().includes(searchQuery) ||
          item.Mailed.toString().toLowerCase().includes(searchQuery) ||
          item.Redeemed_On_Date.toString()
            .toLowerCase()
            .includes(searchQuery) ||
          item.MAILED_DATE.toString().toLowerCase().includes(searchQuery) ||
          item.RECEIVED_METHOD.toString().toLowerCase().includes(searchQuery) ||
          item.DENOMINATION.toString().toLowerCase().includes(searchQuery) ||
          item.EmailAddress.toString().toLowerCase().includes(searchQuery) ||
          item.PHONE_NUMBER.toString().toLowerCase().includes(searchQuery) ||
          item.ZIP.toString().toLowerCase().includes(searchQuery) ||
          item.STATE.toString().toLowerCase().includes(searchQuery) ||
          item.CITY.toString().toLowerCase().includes(searchQuery) ||
          item.ADDRESS.toString().toLowerCase().includes(searchQuery) ||
          item.LAST_NAME.toString().toLowerCase().includes(searchQuery) ||
          item.FIRST_NAME.toString().toLowerCase().includes(searchQuery) ||
          item.CERT_STATUS.toString().toLowerCase().includes(searchQuery) ||
          item.REDEMPTION_ENDS.toString().toLowerCase().includes(searchQuery) ||
          item.REDEMPTION_BEINS.toString()
            .toLowerCase()
            .includes(searchQuery) ||
          item.CERT_NUMBER_1.toString().toLowerCase().includes(searchQuery) ||
          item.CERT_NUMBER.toString().toLowerCase().includes(searchQuery) ||
          item.JOB_NUMBER.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 align-middle">
          <thead>
            <tr>
              <th>JOB_NUMBER</th>
              <th>CERTI INFO</th>
              <th>USER INFO </th>
              <th>ALL DATE</th>
              <th>Agent</th>
              <th>OTHER INFO</th>
            </tr>
          </thead>
          <tbody>
            {slice.length ? (
              slice.map((el, index) => (
                <tr key={index}>
                  <td>{el.JOB_NUMBER}</td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>CERT_NUMBER : </strong>
                      {el.CERT_NUMBER}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CERT_NUMBER_1 : </strong>
                      {el.CERT_NUMBER_1}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>REDEMPTION_BEINS : </strong>
                      {el.REDEMPTION_BEINS}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>REDEMPTION_ENDS : </strong>
                      {el.REDEMPTION_ENDS}
                    </div>
                    <div className="mt-1 mb-1">
                      <strong>CERT_STATUS : </strong>
                      {el.CERT_STATUS}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>FIRST_NAME : </strong>
                      {el.FIRST_NAME}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>LAST_NAME : </strong>
                      {el.LAST_NAME}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>ADDRESS : </strong>
                      {el.ADDRESS}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CITY : </strong>
                      {el.CITY}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>STATE : </strong>
                      {el.STATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>ZIP : </strong>
                      {el.ZIP}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>PHONE_NUMBER : </strong>
                      {el.PHONE_NUMBER}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>STATE : </strong>
                      {el.STATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>EmailAddress : </strong>
                      {el.EmailAddress}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>DENOMINATION : </strong> {el.DENOMINATION}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>RECEIVED_METHOD : </strong> {el.RECEIVED_METHOD}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>RECIEVED_DATE : </strong>
                      {el.Redeemed_On_Date}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>MAILED_DATE : </strong>
                      {el.MAILED_DATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>status_date : </strong>
                      {el.status_date}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Mailed : </strong>
                      {el.Mailed}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>Agent : </strong>
                      {el.Agent}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Agent Date Time : </strong>
                      {el.AgentDateTime}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>UNIQUE_ID : </strong> {el.UNIQUE_ID}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CARD_ORDERED : </strong> {el.CARD_ORDERED}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>FILE_NOTES : </strong> {el.FILE_NOTES}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CODE : </strong> {el.CODE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CampaignIDNo : </strong> {el.CampaignIDNo}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Campaign : </strong> {el.Campaign}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Certificate </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
