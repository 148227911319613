import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import $ from "jquery";
import { useFormik } from "formik";
import { addSubCom } from "../../../schema";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CKEditor } from "ckeditor4-react";

import he from "he";


export default function EditSubCompany() {
  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;
  const { id } = useParams();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [changeColor, setChangeColor] = useState(false);

  const [landingLogo, setLandingLogo] = useState("");
  const [homeLogo, setHomeLogo] = useState("");
  const [thankLogo, setThankLogo] = useState("");
  const [sitesList, setsitesList] = useState([]);
  const [editorData, setEditorData] = useState("");


  useEffect(() => {
    if (admin_id) {
      const getCustomerById = async () => {
        try {
          setLoading(true);
         

          const responce = await AdminListService.listSubCompanyById(id);
          if (responce.status === 200) {
            setLoading(false);
            setValues({
              parent_id: responce.data.data.data_parent.parent_id,
              id: responce.data.data.Adata.AdminId,
              siteName: responce.data.data.Sdata.SiteName,
              userName: responce.data.data.Adata.UserName,
              password: responce.data.data.Adata.Password,
              email: responce.data.data.Adata.Email,
              visio: responce.data.data.Sdata.na_visio,
              pedType: responce.data.data.Sdata.product_type,
              subject: responce.data.data.Sdata.email_subject,
              body: he.decode(responce.data.data.Sdata.email_body),
            });
            const bodyData = he.decode(responce.data.data.Sdata.email_body);
            setEditorData(bodyData);
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: "Some thing went wrong!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      };

      getCustomerById();
      const getParant = async () => {
        setLoading(true);
        try {
          const responce = await AdminListService.parentSites();

          if (responce.status === 200) {
            const res = responce.data.data;
            const results = [];
            res.map((value) => {
              return results.push({
                id: value.SiteId,
                name: value.SiteName,
              });
            });

            setsitesList([...results]);

            setLoading(false);
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
          setsitesList([]);
        }
      };
      getParant();
    }

    // eslint-disable-next-line
  }, []);

  const changeIt = (e) => {
    if (e.target.checked) {
      $(".change-color").fadeOut();
      $(".c-color").css("color", "#000");
      setChangeColor(true);
    } else {
      $(".change-color").fadeIn();
      $(".c-color").css("color", "#fff");
      setChangeColor(false);
    }
  };

  const handleLandingLogoUpload = async (e) => {
    setLandingLogo(e.target.files[0]);
  };

  const handleHomeLogoUpload = async (e) => {
    setHomeLogo(e.target.files[0]);
  };

  const handleThankLogoUpload = async (e) => {
    setThankLogo(e.target.files[0]);
  };

  const updateSubCustomer = async (values, action) => {
    setLoading(true);

    var backgroundGrade = await $("#target").css("background-image");
    var cleanColor = await backgroundGrade.replace(/-webkit-/g, "");

    const formData = new FormData();
    formData.append("logoImage", landingLogo);
    formData.append("logoImage1", homeLogo);
    formData.append("logoImage2", thankLogo);

    formData.append("admin_id", parseInt(admin_id));

    formData.append("AdmId", values.id);
    formData.append("parentSite", values.parent_id);

    formData.append("emailSubject", values.subject);
    formData.append("emailBody", he.encode(values.body));
    formData.append("username", values.userName);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("siteName", values.siteName);
    formData.append("productType", values.pedType);
    formData.append("naVisio", values.visio);
    formData.append(
      "gradxCode",
      "background:" +
        cleanColor +
        "; \n" +
        "background: -o-" +
        cleanColor +
        "; \n" +
        " background: -ms-" +
        cleanColor +
        "; \n" +
        "background: -moz-" +
        cleanColor +
        "; \n" +
        "background: -webkit-" +
        cleanColor
    );
    formData.append("changeColor", changeColor);

    try {
  
      const responce = await AdminListService.subCompanyUpdate(formData, id);

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/sub_company");
        }, 1500);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      parent_id: "",
      id: "",
      siteName: "",
      userName: "",
      password: "",
      email: "",
      visio: "",
      pedType: "",
      subject: "",
      body: "",
    },
    validationSchema: addSubCom,
    onSubmit: (values, action) => {
      updateSubCustomer(values, action);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Edit Customer
                <button
                  type="button"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </h5>
            </div>

            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-4">
                  <label className="form-label">Select Parent Site </label>
                  <select
                    className="form-select"
                    name="parent_id"
                    value={values.parent_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Please select a parent company </option>
                    {sitesList.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.parent_id && touched.parent_id ? (
                    <span className="text-danger">{errors.parent_id}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Site Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Site Name"
                    name="siteName"
                    value={values.siteName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.siteName && touched.siteName ? (
                    <span className="text-danger">{errors.siteName}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="userName"
                    value={values.userName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.userName && touched.userName ? (
                    <span className="text-danger">{errors.userName}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Password </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Email </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Na-visio Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Na-visio Number"
                    name="visio"
                    value={values.visio || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.visio && touched.visio ? (
                    <span className="text-danger">{errors.visio}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Product Type</label>
                  <select
                    className="form-select"
                    name="pedType"
                    value={values.pedType || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Product Type</option>
                    <option value="Ordinary PYP">Ordinary PYP</option>
                    <option value="Gorilla PYP">Gorilla PYP</option>
                  </select>
                  {errors.pedType && touched.pedType ? (
                    <span className="text-danger">{errors.pedType}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="formFileMultiple" className="form-label">
                    landing page logo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onClick={(e) => {
                      e.target.value = null;
                      setLandingLogo("");
                    }}
                    onChange={handleLandingLogoUpload}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Home page logo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onClick={(e) => {
                      e.target.value = null;
                      setHomeLogo("");
                    }}
                    onChange={handleHomeLogoUpload}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Thankyou logo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onClick={(e) => {
                      e.target.value = null;
                      setThankLogo("");
                    }}
                    onChange={handleThankLogoUpload}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="clearfix"></div>
                <div className="col-12 col-md-7">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Auto Generated Email Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    name="subject"
                    value={values.subject || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.subject && touched.subject ? (
                    <span className="text-danger">{errors.subject}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-12">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Email body
                  </label>
                  {editorData && (
                    <CKEditor
                      initData={editorData}
                      config={{
                        allowedContent: true,
                        toolbar: [
                          ["Source"],
                          ["Styles", "Format", "Font", "FontSize"],
                          [
                            "Cut",
                            "Copy",
                            "Paste",
                            "PasteText",
                            "PasteFromWord",
                            "-",
                            "Undo",
                            "Redo",
                          ],
                          [
                            "Bold",
                            "Italic",
                            "Strike",
                            "UnderLine",
                            "RemoveFormat",
                          ],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "NumberedList",
                            "BulletedList",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "Blockquote",
                            "CreateDiv",
                            "-",
                            "JustifyLeft",
                            "JustifyCenter",
                            "JustifyRight",
                            "JustifyBlock",
                            "-",
                            "BidiLtr",
                            "BidiRtl",
                            "Language",
                          ],
                          ["EasyImageUpload"],
                          ["TextColor", "BGColor"],
                          ["Maximize", "ShowBlocks"],
                        ],
                        extraPlugins: "easyimage",
                        removePlugins: "image",
                        cloudServices_uploadUrl:
                          "https://33333.cke-cs.com/easyimage/upload/",
                        cloudServices_tokenUrl:
                          "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                      }}
                      onInstanceReady={(e) => {
                        // if (secureLocalStorage.getItem("body")) {
                        //   const decodedHTML = he.decode(
                        //     he.decode(secureLocalStorage.getItem("body"))
                        //   );
                        //   e.editor.setData(decodedHTML);
                        //   secureLocalStorage.removeItem("body");
                        // }
                      }}
                      onChange={(e) => {
                        var data = e.editor.getData();

                        setFieldValue("body", data);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="wrap-colors">
                    <div id="gradX"></div>
                    <div className="targets">
                      <div className="target" id="target">
                        <div className="target_text">Target #1</div>
                      </div>
                      <div className="target" id="target2">
                        <div className="target_text">Target #2</div>
                      </div>
                    </div>
                    <div className="change-color"></div>
                    <div className="checkbox ">
                      <label className="c-color">
                        <input
                          type="checkbox"
                          name="change-color"
                          className="change form-check-input"
                          onChange={changeIt}
                        />{" "}
                        Enable Site Colors Editing.
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-md-4 m-0">
                  <div className="d-flex h-100 w-100 align-items-end mb-mob">
                    <button className="btn btn-primary w-100">
                      Save Customer Record
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
