import { CKEditor } from "ckeditor4-react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { plasticAddSite } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import he from "he";

export default function EditSitePlastic() {
  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;
  const { id } = useParams();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState("");
  

  useEffect(() => {
    if (admin_id) {
      const getPypSite = async () => {
        try {
          setLoading(true);
       
          const responce = await AdminListService.listPlasticById(id);

          if (responce.status === 200) {
            setLoading(false);
            setValues({
              id: responce.data.data.Adata.AdminId,
              siteName: responce.data.data.Sdata.SiteName,
              userName: responce.data.data.Adata.UserName,
              password: responce.data.data.Adata.Password,
              email: responce.data.data.Adata.Email,
              visio: responce.data.data.Sdata.na_visio,
              pedType: responce.data.data.Sdata.product_type,
              subject: responce.data.data.Sdata.email_subject,
              body: responce.data.data.Sdata.email_body,
            });
            const bodyData = he.decode(responce.data.data.Sdata.email_body);
            setEditorData(bodyData);
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: "Some thing went wrong!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      };

      getPypSite();
    }

    // eslint-disable-next-line
  }, []);

  const updatePlasticSite = async (values) => {
    setLoading(true);

    try {
   

      const responce = await AdminListService.sitePlasticUpdate(
        id,
        values,
        admin_id
      );

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/pickyourplastic_manage");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      id: "",
      siteName: "",
      userName: "",
      password: "",
      email: "",
      visio: "",
      pedType: "",
      subject: "",
      body: "",
    },
    validationSchema: plasticAddSite,
    onSubmit: (values) => {
      updatePlasticSite(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Edit Customer</h5>
            </div>

            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-4">
                  <label className="form-label">Site Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Site Name"
                    name="siteName"
                    value={values.siteName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.siteName && touched.siteName ? (
                    <span className="text-danger">{errors.siteName}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="userName"
                    value={values.userName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.userName && touched.userName ? (
                    <span className="text-danger">{errors.userName}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Password </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Email </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-4">
                  <label className="form-label">Na-visio Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Na-visio Number"
                    name="visio"
                    value={values.visio || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.visio && touched.visio ? (
                    <span className="text-danger">{errors.visio}</span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Product Type</label>
                  <select
                    className="form-select"
                    name="pedType"
                    value={values.pedType || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Product Type</option>
                    <option value="Ordinary PYP">Ordinary PYP</option>
                    <option value="Gorilla PYP">Gorilla PYP</option>
                  </select>
                  {errors.pedType && touched.pedType ? (
                    <span className="text-danger">{errors.pedType}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-7">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Auto Generated Email Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    name="subject"
                    value={values.subject || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.subject && touched.subject ? (
                    <span className="text-danger">{errors.subject}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-12">
                  <label htmlFor="formFileMultiple" className="form-label">
                    Email body
                  </label>
                  {editorData && (
                    <CKEditor
                      initData={editorData}
                      config={{
                        allowedContent: true,
                        toolbar: [
                          ["Source"],
                          ["Styles", "Format", "Font", "FontSize"],
                          [
                            "Cut",
                            "Copy",
                            "Paste",
                            "PasteText",
                            "PasteFromWord",
                            "-",
                            "Undo",
                            "Redo",
                          ],
                          [
                            "Bold",
                            "Italic",
                            "Strike",
                            "UnderLine",
                            "RemoveFormat",
                          ],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "NumberedList",
                            "BulletedList",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "Blockquote",
                            "CreateDiv",
                            "-",
                            "JustifyLeft",
                            "JustifyCenter",
                            "JustifyRight",
                            "JustifyBlock",
                            "-",
                            "BidiLtr",
                            "BidiRtl",
                            "Language",
                          ],
                          ["EasyImageUpload"],
                          ["TextColor", "BGColor"],
                          ["Maximize", "ShowBlocks"],
                        ],
                        extraPlugins: "easyimage",
                        removePlugins: "image",
                        cloudServices_uploadUrl:
                          "https://33333.cke-cs.com/easyimage/upload/",
                        cloudServices_tokenUrl:
                          "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                      }}
                      onInstanceReady={(e) => {
                        var data = e.editor.getData();
                        const bodyData = he.encode(data);

                        setFieldValue("body", bodyData);
                      }}
                      onChange={(e) => {
                        var data = e.editor.getData();
                        const bodyData = he.encode(data);

                        setFieldValue("body", bodyData);
                      }}
                    />
                  )}
                </div>

                <hr />

                <div className="col-md-4 m-0">
                  <div className="d-flex h-100 w-100 align-items-end mb-mob">
                    <button className="btn btn-primary w-100" type="submit">
                      Update Customer Record
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
