import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Swal from "sweetalert2";
import $ from "jquery";
import { useSelector } from "react-redux";

export default function ManageCard() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const cardList = async (page) => {
    setLoading(true);

    const val = await $("#cardSearch").val();

    try {
      const responce = await AdminListService.listCard(page, val);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            CardId: value.CardId,
            CardName: value.CardName,
            CardImage: value.CardImage,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardList("1");
    }
  }, [adminAccessToken]);

  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  };

  const delSelected = async () => {
    var selectedRecords = await $(" tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Please select some record!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete record",
        icon: "question",
        iconColor: "#e04f5d",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",

        confirmButtonText: "Yes",
        cancelButtonText: "Close",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);

          try {
            const response = await AdminListService.deletSelectedCard(
              selectedRecords
            );
            if (response.status === 200) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });

              setTimeout(() => {
                cardList(currentPage);
              }, 1500);
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              confirmButtonColor: "#e72e2e",
              text: "Something went wrong!",
            });
            setLoading(false);
          }
        }
      });
    }
  };

  const searchCertificates = async (e) => {
    setLoading(true);

    const keyVal = e.target.value;
    try {
      const responce = await AdminListService.searchCard(keyVal);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            CardId: value.CardId,
            CardName: value.CardName,
            CardImage: value.CardImage,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 align-middle">
          <thead>
            <tr>
              <th scope="col">
                <input
                  className="form-check-input slect_all"
                  type="checkbox"
                  value=""
                  onChange={allSelect}
                />
              </th>
              <th scope="col">Card Name</th>
              <th scope="col">Card Image</th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {slice.length ? (
              slice.map((el, index) => (
                <tr key={index}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={el.CardId}
                      value={el.CardId}
                    />
                  </td>
                  <td>{el.CardName}</td>
                  <td>
                    {el.CardImage ? (
                      <img
                        src={`${process.env.REACT_APP_IMG_LINK}/resources/images/cards/${el.CardImage}`}
                        className="card-logo-img"
                        alt="No Card"
                      />
                    ) : (
                      <div className="pirds pirds2">
                        <span className="rdcrdtop">
                          <table border="0">
                            <tbody>
                              <tr>
                                <td>{el.CardName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </span>
                        <span className="rdcrd">REWARD CARD</span>
                      </div>
                    )}
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="btn btn-sm btn-primary"
                        to={"/card_edit/" + el.CardId}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              cardList(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Cards</h5>
            </div>

            <div className="card-body">
              <Link to="/card_add" className="btn btn-primary mt-2 btn-sm ">
                <i className="bi bi-plus-circle-fill"></i> Add Card
              </Link>

              <button
                className="btn btn-primary mt-2 btn-sm ms-2"
                onClick={delSelected}
              >
                <i className="bi bi-trash-fill"></i> Delete Selected Cards
              </button>

              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-8 col-lg-4 ms-auto">
                  <div className="input-group mt-2 mb-2">
                    <select className="form-select status-dropdown">
                      <option value="CardName">Card Name</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      id="cardSearch"
                      onChange={(e) => {
                        searchCertificates(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Table data={tableData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
