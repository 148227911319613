import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function SubCompany() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [limit, setLimit] = useState("20");

  const getSubCompany = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listSubCompany(page);

      if (responce.status === 200) {
        let res;
        if (Array.isArray(responce?.data?.data?.data)) {
          res = responce?.data?.data?.data;
        } else if (typeof responce?.data?.data?.data === "object") {
          res = Object.values(responce.data.data.data);
        }

        const results = [];
        res.map((value) => {
          return results.push({
            SiteId: value.SiteId,
            SiteName: value.SiteName,
            SiteUrl: value.SiteUrl,
            UserName: value.UserName,
            Password: value.Password,
            SubSiteName: value.SubSiteName,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      getSubCompany("1");
    }
    // eslint-disable-next-line
  }, []);

  const delSite = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteSubCompany(delId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getSubCompany("1");
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.SiteName.toString().toLowerCase().includes(searchQuery) ||
          item.SubSiteName.toString().toLowerCase().includes(searchQuery) ||
          item.Password.toString().toLowerCase().includes(searchQuery) ||
          item.UserName.toString().toLowerCase().includes(searchQuery) ||
          item.SiteUrl.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("SiteName")}
                className={getClassNamesFor("SiteName")}
              >
                Parent Site Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("SiteUrl")}
                className={getClassNamesFor("SiteUrl")}
              >
                Parent Site Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("SubSiteName")}
                className={getClassNamesFor("SubSiteName")}
              >
                Child Site Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("UserName")}
                className={getClassNamesFor("UserName")}
              >
                Child Site User Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("Password")}
                className={getClassNamesFor("Password")}
              >
                Child Site Password
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Active")}
                className={getClassNamesFor("Active")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{el.SiteName}</td>
                  <td>{el.SiteUrl}</td>
                  <td>{el.SubSiteName}</td>
                  <td>{el.UserName}</td>
                  <td>{el.Password}</td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="text-warning"
                        to={"/edit_sub_company/" + el.SiteId}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                      <button
                        type="button"
                        onClick={() => {
                          delSite(el.SiteId);
                        }}
                        className="text-danger open-model-btn"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* 
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        /> */}
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={rowsPerPage}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="alert border-0 bg-dark alert-dismissible fade show py-2">
            <div className="d-flex align-items-center">
              <div className="fs-3 text-white">
                <i className="bi bi-bell-fill"></i>
              </div>
              <div className="ms-3">
                <div className="text-white font-20">
                  Delete button will appear only when a customer has nothing
                  uploaded
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Customers</h5>
            </div>

            <div className="card-body">
              <Link
                to="/add_sub_company"
                className="btn btn-primary mt-2 btn-sm float-md-start"
              >
                <i className="bi bi-plus-circle-fill"></i> Add Sub Customers
              </Link>
              <a
                href={process.env.REACT_APP_API_Link + "SubCompnDownload"}
                className="btn btn-primary mt-2 btn-sm  float-md-end"
              >
                <i className="bi bi-cloud-download-fill"></i> Export As Excel
              </a>
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
