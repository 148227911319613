import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";

export default function ViewCertificateDetail() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [filteredData, setFilteredData] = useState([]);

  const cardDetail = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listCardDetail(page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            Active: value.Active,
            CUSTOMER: value.CUSTOMER,
            cardMailed: value.cardMailed,
            cardOrdered: value.cardOrdered,
            count: value.count,
            expired: value.expired,
            void: value.void,
            voucherMailed: value.voucherMailed,
            voucherRecieved: value.voucherRecieved,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardDetail("1");
    }
  }, [adminAccessToken]);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.Active.toString().toLowerCase().includes(searchQuery) ||
          item.CUSTOMER.toString().toLowerCase().includes(searchQuery) ||
          item.cardMailed.toString().toLowerCase().includes(searchQuery) ||
          item.cardOrdered.toString().toLowerCase().includes(searchQuery) ||
          item.count.toString().toLowerCase().includes(searchQuery) ||
          item.expired.toString().toLowerCase().includes(searchQuery) ||
          item.void.toString().toLowerCase().includes(searchQuery) ||
          item.voucherMailed.toString().toLowerCase().includes(searchQuery) ||
          item.voucherRecieved.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths certificate-details">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("CUSTOMER")}
                className={getClassNamesFor("CUSTOMER")}
              >
                Company Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Active")}
                className={getClassNamesFor("Active")}
              >
                Active
              </th>
              <th
                scope="col"
                onClick={() => requestSort("voucherMailed")}
                className={getClassNamesFor("voucherMailed")}
              >
                Voucher Mailed
              </th>

              <th
                scope="col"
                onClick={() => requestSort("voucherRecieved")}
                className={getClassNamesFor("voucherRecieved")}
              >
                Voucher Received
              </th>

              <th
                scope="col"
                onClick={() => requestSort("cardOrdered")}
                className={getClassNamesFor("cardOrdered")}
              >
                Card Ordered
              </th>

              <th
                scope="col"
                onClick={() => requestSort("cardMailed")}
                className={getClassNamesFor("cardMailed")}
              >
                Card Mailed
              </th>

              <th
                scope="col"
                onClick={() => requestSort("expired")}
                className={getClassNamesFor("expired")}
              >
                Expired
              </th>

              <th
                scope="col"
                onClick={() => requestSort("void")}
                className={getClassNamesFor("void")}
              >
                Void
              </th>

              <th
                scope="col"
                onClick={() => requestSort("count")}
                className={getClassNamesFor("count")}
              >
                Totals
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{el.CUSTOMER}</td>

                  <td>
                    <Link to={`/certdetail/Active/${el.CUSTOMER}`}>
                      {el.Active}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/voucherMailed/${el.CUSTOMER}`}>
                      {el.voucherMailed}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/voucherRecieved/${el.CUSTOMER}`}>
                      {el.voucherRecieved}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/cardOrdered/${el.CUSTOMER}`}>
                      {el.cardOrdered}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/cardMailed/${el.CUSTOMER}`}>
                      {el.cardMailed}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/expired/${el.CUSTOMER}`}>
                      {el.expired}
                    </Link>
                  </td>

                  <td>
                    <Link to={`/certdetail/void/${el.CUSTOMER}`}>
                      {el.void}
                    </Link>
                  </td>

                  <td>{el.count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage PYP Lookup</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
